import React from "react";

import * as IS from "./validator";
import Popup from "./popup";
import { request } from "./functions";
import loading_img from "../loading.png";

const voidf = () => {};

/**
 * Display a Generic Input Field
 * @param {Object} props 
 * @param {string} props.name 
 * @param {string} props.type - Default Text
 * @param {*} props.value
 * @param {Function} props.onChange 
 * @param {boolean} props.disabled 
 * @param {*} props.style 
 * @param {boolean} props.inLine 
 * @param {boolean} props.noSpan 
 * @param {string} props.label 
 * @param {*} props.spanStyle 
 * @param {*} props.divStyle 
 * @param {number} props.maxlength
 * @param {string} props.pattern - REGEX Expression
 * @param {number} props.size 
 * @param {string} props.placeholder 
 * 
 */
export const InputField = (props) => {
    const { 
        name, label, type, value, onChange, inLine, lowercase, 
        noSpan, spanStyle, divStyle, size, uppercase, ...other 
    } = props;

    function change(event) {
        if (uppercase && event.target.value) event.target.value = event.target.value.toUpperCase();
        if (lowercase && event.target.value) event.target.value = event.target.value.toLowerCase();
        if (IS.string(type) && typeof IS[type] === "function") {
            if (IS[type](event.target.value)) event.target.style.borderColor = "green";
            else event.target.style.borderColor = "red";            
        } else event.target.style.borderColor = "initial";       
        if (onChange) onChange({ target: { name: name, value: event.target.value, type: type }});
    }

    let newType = type;
    if (IS.object(value)) newType = "textarea";
    else if (IS.array(value)) newType = "textarea";
    else if (IS.error(value)) newType = "text";
    else if (typeof value === "function") newType = "text";

    let newValue = value;
    if (IS.object(value)) newValue = JSON.stringify(value);
    else if (IS.array(value)) newValue = value.toString();
    else if (IS.error(value)) newValue = value.message;
    else if (typeof value === "function") newValue = "-- function --";

    let dinamicSize = size || 20;
    if (newValue && newValue.length) dinamicSize = newValue.length + 2;
    if (size && (dinamicSize < size)) dinamicSize = size;
    else if (dinamicSize < 20) dinamicSize = 20;

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {(!noSpan) && (<span style={spanStyle}>{label || name}</span>)}
            {(IS.string(newType) && newType === "textarea") ? (
                <textarea 
                    name={name}
                    value={newValue}
                    onChange={onChange || voidf}
                    {...other}
                />
            ) : (
                <input 
                    name={name}
                    value={newValue} 
                    type={newType}
                    onChange={change}
                    size={dinamicSize}
                    {...other}
                />
            )}
        </div>  
    )
}
export const Button = (props) => {
    const { name, label, value, onClick, onChange, divStyle, style, disabled } = props;

    function click(event) {
        if (onClick || onChange) {
            event.target.classList.add("bounceButton");
            event.target.addEventListener("animationend", () => { event.target.classList.remove("bounceButton") });
            if (onClick) onClick(event);            
            if (onChange && name) {
                let ev = { target: { name: name, type: typeof value }};
                if (IS.boolean(value)) ev.target.value = !value;
                else ev.target.value = value;                
                onChange(ev);
            }
        }        
    }

    let disp = "-";
    if (IS.string(label)) disp = label;
    else if (IS.string(name)) disp = name;
    else if (typeof value !== "undefined") disp = `${value}`;

    return (
        <div className="inputContainer" style={divStyle}>
            <button onClick={click} value={value} style={style} disabled={disabled}>{disp}</button>
        </div>
    )
}
/**
 * Display a Dropdown Select Field
 * @param {Object} props 
 * @param {string} props.name 
 * @param {*} props.value
 * @param {Function} props.onChange 
 * @param {*} props.options 
 * @param {*} props.style  
 * @param {boolean} props.inLine 
 * @param {string} props.label 
 * @param {string} props.type
 * @param {boolean} props.noVoid 
 * @param {*} props.spanStyle 
 * @param {*} props.divStyle 
 * @param {boolean} props.disabled 
 * @param {boolean} props.noSpan 
 * 
 */
export const SelectField = (props) => {
    const { 
        name, label, type, value, onChange, options, inLine, 
        divStyle, disabled, noSpan, noVoid, hover, ...other
    } = props;

    function change(event) {
        if (!disabled) {
            let newValue = event.target.value; 
            if (IS.string(type) && type === "number") newValue = Number(newValue);
            if (onChange) onChange({ target: { value: newValue, name: event.target.name, type: type } });
        }
    }

    let opts = [];
    if (IS.array(options)) {
        options.filter(e => !IS.object(e)).forEach((el) => {
            opts.push({ value: el, label: el })
        })
    } else if (IS.object(options)) {
        Object.entries(options).forEach(([key, val]) => {
            opts.push({ value: val, label: key })
        })
    } 

    let dis = disabled;
    if (opts.length === 0) dis = true;
    if (!noVoid) opts.unshift({ value: "", label: "" });

    let common = { ...other };
    if (IS.string(hover)) common = { ...common, onMouseOver: enter, onMouseOut: leave };

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {!noSpan && (<span>{label || name}</span>)}
            {IS.string(hover) && (<Hover hover={hover} />)}
            <select name={name} value={value} onChange={change} disabled={dis} {...common}>               
                {opts.map((opt, ind) => (
                    <option key={`select${name}.option${ind}`} value={opt.value}>{opt.label}</option>
                ))}                
            </select>
        </div> 
    )
}

class NewPassword extends React.Component {
    state = {
        password: "",
        repeat: "",
        strength: {
            length: false,
            uppercase: false,
            lowercase: false,
            special: false,
            number: false
        },
        params: {
            length: 8,
            uppercase: false,
            lowercase: false,
            special: false,
            number: false
        },
        texts: {
            length: `Lunghezza almeno @val@ caratteri`,
            uppercase: `Almeno una lettera Maiuscola`,
            lowercase: `Almeno una lettera Minuscola`,
            special: `Almeno un carattere Speciale`,
            number: `Almeno un Numero`,
        },
        label: "NEW PASSWORD",
        disabled: true,
        loading: false
    }

    constructor(props) {
        super(props);
        if (props.label) this.state.label = props.label;
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        try {
            this.setState({ loading: true });
            const r = await request("login/getPasswordStrength");
            if (r.statusCode === 200) {
                let ob = {};
                for (const [k, v] of Object.entries(r.body)) {
                    if (IS.number(v)) ob[k] = false;
                    else if (IS.boolean(v) && v === true) ob[k] = false;
                }
                this.setState({ params: r.body, strength: ob });
            } else Popup(r);
            this.setState({ loading: false });
        } catch(e) {
            Popup(e);
            this.setState({ loading: false });
        }
    }
    
    handleNew = (event) => {
        const { value } = event.target;
        const { onChange, name } = this.props;
        const { params } = this.state;
        if (IS.object(params)) {
            let s = {};
            if (IS.number(params.length)) s.length = value.length >= params.length;
            if (IS.boolean(params.uppercase) && params.uppercase === true) s.uppercase = /[A-Z]/.test(value);
            if (IS.boolean(params.lowercase) && params.lowercase === true) s.lowercase = /[a-z]/.test(value);
            if (IS.boolean(params.number) && params.number === true) s.number = /[0-9]/.test(value);
            if (IS.boolean(params.special) && params.special === true) s.special = /[^A-Za-z0-9]/.test(value);
            let ok = true;
            for (const v of Object.values(s)) {
                if (v === false) {
                    ok = false;
                    break;
                }
            }
            if (ok === true) {
                this.setState({ disabled: false });
                event.target.style.borderColor = "green";
            } else {
                this.setState({ disabled: true, repeat: "" });
                if (onChange) onChange({ target: { name: name, value: "" }});
                event.target.style.borderColor = "red";
            }
            this.setState({ [event.target.name]: value, strength: s });
        }
    }

    handleRepeat = (event) => {
        const { value } = event.target;
        const { password } = this.state;
        const { onChange, name } = this.props;
        this.setState({ [event.target.name]: value });
        if (value === password) {
            event.target.style.borderColor = "green";
            if (onChange) onChange({ target: { name: name, value: password }});
        } else {
            event.target.style.borderColor = "red";
            if (onChange) onChange({ target: { name: name, value: "" }});
        }        
    }

    render() {
        const { password, repeat, strength, label, disabled, params, texts, loading } = this.state;
        const { inLine, placeholder, size, divStyle } = this.props;

        let dinamicSize = IS.number(size) ? size : 20;
        if (IS.string(password) && dinamicSize < (password.length + 2)) dinamicSize = password.length + 2;

        if (loading) return (
            <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
                {!inLine && (<span>{label}</span>)} 
                <img src={loading_img} alt="loading" className="spin" /> 
            </div>
        )
        else return (
            <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
                {!inLine && (<span>{label}</span>)} 
                <input 
                    type="password" 
                    value={password} 
                    name="password" 
                    onChange={this.handleNew} 
                    placeholder={placeholder}
                    size={dinamicSize}
                    disabled={this.props.disabled}
                />        
                <div className="passwordStrength">
                    {(IS.object(strength) && Object.keys(strength).length) && (
                        <React.Fragment>
                            {Object.entries(strength).map(([key, val]) => (
                                <span key={`strength.${key}`} className={val ? "passed" : ""}>
                                    {IS.string(texts[key]) ? (
                                        <React.Fragment>
                                            {texts[key].includes("@val@") ? texts[key].replaceAll("@val@", `${params[key]}`) : texts[key] }
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {`Require at least one ${key} char`}
                                        </React.Fragment>                                        
                                    )}
                                </span>
                            ))}
                        </React.Fragment>
                    )}
                </div>  
                <input 
                    type="password" 
                    value={repeat} 
                    name="repeat" 
                    onChange={this.handleRepeat} 
                    disabled={disabled} 
                    placeholder={disabled ? "... Password ..." : "... Ripeti ..."}
                    size={dinamicSize}
                />
            </div>
        )
    }
}

export { NewPassword };

/**
 * Display an hover div with message
 * @param {*} text - Text to display, String, Error or Object with Object.result as String
 * @param {number} time - ms before closing div, default 3000 ms
 */
export function HoverDiv(text) { 
    try {
        let timeout;
        let time = 3000;
        let remove = true;
        let txt = "";
        const randomId = `hoverDiv-${Math.floor(Math.random() * 5000)}`;    
        if (arguments.length > 1) {
            for (let i=1; i<arguments.length; i++) {
                if (IS.number(arguments[i])) time = arguments[i];
                else if (IS.string(arguments[i]) && arguments[i] === "noRemove") remove = false;
            }
        }    
        function close() {
            if (document.getElementById(randomId)) document.getElementById(randomId).remove(); 
            if (timeout) clearTimeout(timeout);
        }  
        if (IS.string(text)) txt = text;
        else if (IS.error(text)) txt = text.message;
        else if (IS.object(text) && IS.string(text.result)) txt = text.result;
        else if (IS.object(text) && IS.string(text.message)) txt = text.message;
        const div = createEl(txt, { class: "hoverDiv", id: randomId }, "div", close);
        if (document.getElementById("hovers")) document.getElementById("hovers").appendChild(div);
        else document.body.appendChild(div);        
        if (remove === true) timeout = setTimeout(() => { close() }, time);
    } catch(e) {
        console.log(e);
    }
}
/**
 * Create an HTML Element, Accepted Optional params are:
 * - String: the HTML TAG name, by default is SPAN
 * - Object: Optional element values
 * - Function: If given set the onClick function event to Element
 * - Array: If given set the arguments for onClick function
 * @param {*} con - Content to display, String, Error or Object with Object.result as String
 * @returns HTML Element
 */
export function createEl(con) {
    try {
        let tag = "span";
        let params;
        let text;
        let func;
        let args = [];
        if (IS.string(con)) text = con;
        else if (IS.error(con)) text = con.message;
        else if (IS.object(con) && IS.string(con.result)) text = con.result;
        else text = `${con}`;
        if (arguments.length > 1) {
            for (let i=1; i<arguments.length; i++) {
                if (IS.string(arguments[i])) tag = arguments[i];
                else if (IS.object(arguments[i])) params = arguments[i];
                else if (IS.func(arguments[i])) func = arguments[i];
                else if (IS.array(arguments[i])) args = arguments[i];
            }
        }
        const elem = document.createElement(tag);
        elem.innerHTML = text;
        if (IS.object(params)) {
            for (const [k, v] of Object.entries(params)) {                
                if (IS.string(v)) elem.setAttribute(k, v);
            }
        }
        if (IS.func(func)) elem.onclick = () => { func(...args) };
        return elem;
    } catch(e) {
        console.log(e);
        const span = document.createElement("span");
        span.innerHTML = e.message;
        return span;
    }
}

export function getVal(obj, path) {
    try {
        const value = path.replace(/\[([^\[\]]*)\]/g, '.$1.').split('.').filter(t => t !== '').reduce((prev, cur) => prev && prev[cur], obj)
        return value;
    } catch(e) {
        return e.message;
    }
}

export function setProperty(obj, path, value) {
    const [head, ...rest] = path.split('.');
    return {
        ...obj,
        [head]: rest.length
            ? setProperty(obj[head], rest.join('.'), value)
            : value
    }
}

export function handleChange(event, state) {
    const { name, value, type } = event.target;
    let newValue = value;
    if (type === "number") newValue = Number(value);  
    else if ((type === "hidden")&&(value === "true")) newValue = true;
    else if ((type === "hidden")&&(value === "false")) newValue = false;
    if (name.includes(".")) {
        const parentPath = name.substring(0, name.indexOf("."));
        const childPath = name.substring(name.indexOf(".") + 1);
        return ({ [parentPath]: setProperty(state[parentPath], childPath, newValue) }); 
    } else return ({ [name]: newValue });               
}   
/**
 * 
 * @param {string} azienda - Nome Azienda
 * @param {*} text - String or Array of Strings to show spans
 * @param {number} [resCode] - if valid StatusCode given set text color of spans
 * @param {string} [result] - if given replace all spans text
 * @param {array} [links] - Array of Object to show links, set label as String, query to set query params, href to set href, location to set goUrl 
 * @param {object} [style] - If given merge span style
 * @param {object} [divStyle] - If given set Div disclaimer style
 * @returns 
 */
export const Disclaimer = (props) => {
    const { azienda, text, resCode, result, links, style, divStyle } = props;

    let s = { color: "inherit" };
    if (IS.statusCode(resCode)) {
        if (resCode === 200) s.color = "rgb(0, 255, 0)";
        else if (resCode === 202) s.color = "rgb(0, 255, 128)";
        else if (resCode === 404) s.color = "rgb(255, 0, 0)";
        else if ((resCode >= 300)&&(resCode < 400)) s.color = "rgb(0, 0, 255)";
        else if ((resCode >= 400)&&(resCode < 500)) s.color = "rgb(255, 128, 0)";
        else if ((resCode >= 500)&&(resCode < 600)) s.color = "rgb(255, 0, 0)";
    }
    if (IS.object(style)) s = { ...s, ...style };

    const { protocol, hostname, port, pathname } = window.location;
    let url = `${protocol}//${hostname}`;
    if (port) url += `:${port}`;

    let arr = [];
    if (IS.string(result)) arr.push(result);
    else if (IS.string(text)) {
        if (text.includes("@azienda@")) arr.push(text.replaceAll("@azienda@", azienda));
        else arr.push(text)
    } else if (IS.array(text, true)) {
        for (const t of text) {
            if (IS.string(t) && t.includes("@azienda@")) arr.push(t.replaceAll("@azienda@", azienda));
            else if (IS.string(t)) arr.push(t);
        }
    }

    let lks = [];
    if (IS.array(links, true)) {
        for (const l of links) {
            if (IS.object(l) && IS.string(l.label)) {
                let params = Object.assign({}, l);
                delete params.label;
                if (!IS.string(params.href)) {
                    params.href = url;
                    if (IS.string(params.location)) {
                        if (params.location[0] === "/") params.href += params.location;
                        else params.href += `/${params.location}`;
                        delete params.location;
                    } else params.href += pathname;
                    if (IS.string(params.query)) {
                        params.href += `?${params.query}`;
                        delete params.query;
                    }
                }
                lks.push({ label: l.label, params: params })
            }
        }
    }

    return (
        <div className="disclaimer" style={divStyle}>
            {IS.array(arr, true) && (
                <React.Fragment>
                    {arr.map((el) => (
                        <span style={s}>{el}</span>
                    ))}
                </React.Fragment>
            )}
            {IS.array(lks, true) && (
                <React.Fragment>
                    {lks.map((el) => (
                        <a { ...el.params } style={{ margin: "0.2em 0px" }}>{el.label}</a>
                    ))}
                </React.Fragment>
            )}
        </div> 
    )
}

