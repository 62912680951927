/**
 * Execute an HTTPS REQUEST to backend, accepted params are:
 * - String "noAuth" if given perform request without token login
 * - String ("POST", "GET", "PUT", "DELETE") set the request method, default POST
 * - String ("json", "blob", "text") set the return value type, default json
 * - Object define the body of request
 * - Object.headers define the headers
 * @param {string} api - API path 
 *  
 */
export function request(api) {
    return new Promise(async (resolve, reject) => {
        try {
            const { protocol, hostname, port } = window.location;
            let auth = true;
            let method = "json";
            let token;
            let options = {
                method: "POST",
                headers: {},
                body: {}
            }
            if (arguments.length > 1) {
                for (let i=1; i<arguments.length; i++) {
                    if (arguments[i] && (typeof arguments[i] === "object") && arguments[i].headers) options.headers = arguments[i].headers;
                    else if (arguments[i] && (typeof arguments[i] === "object")) options.body = arguments[i];
                    else if ((typeof arguments[i] === "string") && (arguments[i] === "noAuth")) auth = false;
                    else if (arguments[i] && (typeof arguments[i] === "string") && ["json", "blob", "text"].includes(arguments[i])) method = arguments[i];
                    else if (arguments[i] && (typeof arguments[i] === "string") && ["POST", "GET", "PUT", "DELETE"].includes(arguments[i])) options.method = arguments[i];
                    else if (arguments[i] && typeof arguments[i] === "string") token = arguments[i];
                }
            }
            if (auth) {
                if (!token) token = localStorage.getItem("token");
                options.headers["Authorization"] = `Bearer ${token}`;
            }
            if (method === "json") options.headers["Content-Type"] = "application/json";
            if (options.method === "GET") delete options.body;
            else options.body = JSON.stringify(options.body);
            const r = await fetch(`${protocol}//${hostname}:${port}/${api}`, options); 
            if (r.headers.get("Content-Type") && (r.headers.get("Content-Type").includes("text/html"))) {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, html: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (r.headers.get("Content-Type") && (r.headers.get("Content-Type").includes("text/plain"))) {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, result: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (r.ok && (method === "blob")) {
                try {
                    const result = await r.blob();                        
                    const objectURL = URL.createObjectURL(result);
                    resolve({ statusCode: r.status, objectURL: objectURL }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (method === "text") {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, result: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                }  
            } else if (method === "json") {
                try {
                    let result = await r.json();
                    result.statusCode = r.status;
                    resolve(result); 
                } catch(e) {
                    reject(new Error(e.message));
                }                             
            } else {
                resolve({ statusCode: r.status, result: r.statusText });
            }                 
        } catch(e) {
            reject(new Error(e.message));
        }
    })    
}

export const bounce = (event) => {
    const { target } = event;
    target.classList.add("bounceButton");
    target.addEventListener("animationend", () => { target.classList.remove("bounceButton") });
}
/**
 * 
 * @param {string} url - If complete url go there, if void same page, else backend + url
 * @param {boolean} [blank] - True to Open new window 
 */
export const goUrl = (url, blank) => {
    try {
        const { protocol, hostname, port, pathname } = window.location;
        let dest = `${protocol}//${hostname}:${port}`;
        if (url && isValidUrl(url)) dest = url;
        else if (url && (typeof url === "string")) {
            if (url[0] === "/") dest = dest + url;
            else dest = `${dest}/${url}`;
        } else dest = dest + pathname;        
        if (blank) window.open(dest, "_blank");
        else window.location.href = dest;
    } catch(e) {
        console.log(e);
    }
}


export function isValidUrl(string) {
    try { 
        return Boolean(new URL(string)); 
    } catch(e){ 
        return false; 
    }
}
/**
 * 
 * @param {string} url - If complete url go there, if void same page, else backend + url
 * @returns {string} - The URL string
 */
export function createURL(url) {
    try {
        const { protocol, hostname, port, pathname } = window.location;
        let dest = `${protocol}//${hostname}:${port}`;
        if (url && isValidUrl(url)) dest = url;
        else if (url && (typeof url === "string")) {
            if (url[0] === "/") dest = dest + url;
            else dest = `${dest}/${url}`;
        } else dest = dest + pathname;
        return dest;     
    } catch(e) {
        return null;
    }
}

export function renderDate(el) {
    try {
        if (IS.date(el)) return new Date(el).toLocaleString();
        else if (el instanceof Date && !isNaN(el)) return el.toLocaleString();
        else if (IS.string(el)) return el;
        else return `- Invalid Date -`;
    } catch(e) {
        return e.message;
    }
}