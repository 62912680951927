import React, { useState } from "react";

const Clock = () => {
  let time = new Date().toLocaleTimeString();
  let date = new Date().toLocaleDateString();
  const [ctime, setCtime] = useState(time);
  const [cdate, setCdate] = useState(date);

  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    date = new Date().toLocaleDateString();
    setCtime(time);
    setCdate(date);
  }

  setInterval(UpdateTime, 1000);

  return (
    <div 
      style={{
        position: "fixed",
        top: "0.5em",
        right: "0.5em",
        fontSize: "1.5em"
      }}
    >
      {cdate} {ctime}
    </div>
  )
}

export default Clock;
